<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="closeDialog"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="true"
                :formItems="popForms">
                <m-form
                    :formItems="modifyItems"
                    @getDeliver="getDeliver($event)" 
                    @changeTicketDeliverStatus="changeTicketDeliverStatus($event)"></m-form>
                </m-dialog-content>
            </a-modal>
        <m-form
            :formItems="formItems" 
            @search="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList"
            :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { deliverModify, isValid, machineDeliverDetail, machineDeliverList, machineDeliverSetDeliverer, machineDeliverSetNeedTicket, operatorList, opertorModify } from '../../utils/api'
import { assginDataToArray, findArrObjItem, formatTime, geneIndex } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '订单详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '订单ID', dataIndex: 'orderId', key: 'orderId'},
                 {title: '机器数量', dataIndex: 'number', key: 'number'},
                 {title: '票种', dataIndex: 'ticketTypeText', key: 'ticketTypeText'},
                 {title: '店铺名', dataIndex: 'shopName', key: 'shopName'},
                 {title: '生成票配送订单', dataIndex: 'needTicketText', key: 'needTicketText'},
                 {title: '配送员', dataIndex: 'operatorName', key: 'operatorName'},
                 {title: '收货人姓名', dataIndex: 'consigneeName', key: 'consigneeName'},
                 {title: '收货人电话', dataIndex: 'consigneePhone', key: 'consigneePhone'},
                 {title: '订单状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '创建时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'input', label: '订单ID', value: undefined},
                 {type: 'input', label: '配送员ID', value: undefined},
                 {type: 'selector', key: 'value', options: [{label: '待领取', value: 0}, {label: '已领取', value: 1}, {label: '配送中', value: 5}, {label: '已完成', value: 10}, {label: '已取消', value: 99}], label: '订单状态', value: undefined},
                 {type: 'button', label: '搜索', eventName: 'search'}
             ],
             popForms: [
                 {label: '订单ID', prop: 'orderId', value: '', type: 'input'},
                 {label: '机器数量', prop: 'number', value: '', type: 'input'},
                 {label: '订单状态', prop: 'statusText', value: '', type: 'input'},
                 {label: '店铺ID', prop: 'shopId', value: '', type: 'input'},
                 {label: '店铺名', prop: 'shopName', value: '', type: 'input'},
                 {label: '票种', prop: 'ticketTypeText', value: '', type: 'input'},
                 {label: '配送方式', prop: 'transportTypeText', value: '', type: 'input'},
                 {label: '快递公司', prop: 'expressCompany', value: '', type: 'input'},
                 {label: '快递单号', prop: 'expressNo', value: '', type: 'input'},
                 {label: '收货人姓名', prop: 'consigneeName', value: '', type: 'input'},
                 {label: '收货人电话', prop: 'consigneePhone', value: '', type: 'input'},
                 {label: '收货人地址', props: ['province', 'city', 'county', 'address'], value: '', type: 'input'},
                 {label: '创建时间', prop: 'createTime', value: '', type: 'input'},
            ],
            modifyItems: [
                {label: '配送员', type: 'selector', value: '', options: [], key: 'name', button: {eventName: 'getDeliver', label: '修改'}},
                {label: '是否生成票配送单', type: 'selector', value: '', key: 'value', options: [{label: '是', value: 1}, {label: '否', value: 0}], button: {eventName: 'changeTicketDeliverStatus', label: '修改'}}
            ],
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             selectedIDs: [],
             loading: false,
             status: 0,
        })
        // 初始化单据状态
        function initStatusText(data) {
            data.forEach(ele => {
                const s = ele.status 
                ele.statusText = s == 0 ? '待领取'
                                :s == 1 ? '已领取'
                                :s == 5 ? '配送中'
                                :s == 10 ? '已完成' : '已取消'
            })
            return data
        }
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const items = state.formItems
                const params = {
                    pageNum:  state.currentPage,
                    pageSize: state.pageSize,
                    orderId: items[0].value,
                    operatorId: items[1].value,
                    status: items[2].value
                }
                const result = await machineDeliverList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.records, state.pageSize, state.currentPage)
                    state.tableList = initStatusText(state.tableList)
                    state.tableList.forEach(ele => {
                        ele.needTicketText = ele.needTicket ? '是' : '否'
                        ele.ticketTypeText = ele.ticketType == 'TC' ? '体彩' : '福彩'
                        ele.createTime = formatTime(ele.createTime)
                    })
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        async function detail(event) {
            try {
                const result = await machineDeliverDetail(event.orderId)
                if (isValid(result)) {
                    const d = result.data 
                    d.transportTypeText = d.transportType == 1 ? '自营' : '第三方'
                    const s = d.status
                    d.statusText = s == 0 ? '待领取' 
                                  :s == 1 ? '已领取'
                                  :s == 5 ? '配送中'
                                  :s == 10 ? '已完成'
                                  :s == 99 ? '已取消' : '' 
                    result.data.ticketTypeText = result.data.ticketType == 'TC' ? '体彩' : '福彩'
                    result.data.createTime = formatTime(result.data.createTime)
                    state.popForms = assginDataToArray(result.data, state.popForms)
                    state.modifyItems[0].value = result.data.operatorName
                    state.modifyItems[1].value = result.data.needTicket ? '是' : '否'
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 获取配送人员信息
        async function loadDeliver() {
            try {
                const params = {
                    status: 10
                }
                const result = await operatorList(params)
                if (isValid(result)) {
                    const options = []
                    result.data.records.forEach((ele => {
                        ele.label = ele.name 
                        ele.value = ele.id
                        options.push(ele)
                    }))
                    state.modifyItems[0].options =  options
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadDeliver()
        async function getDeliver(e) {
            try {
                if (e.status == 10) {
                    message.warning('已完成订单不能修改')
                    return
                }
                const choosedItem = findArrObjItem('label', e, state.modifyItems[0].options)
                const params = {
                    orderId: state.popForms[0].value,
                    operatorId: choosedItem.id
                }
                const result = await machineDeliverSetDeliverer(params)
                if (isValid(result)) {
                    message.success('配送人员修改成功')
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 修改激活设备时是否同时生成配送订单状态
        async function changeTicketDeliverStatus(value) {
            try {
                const chooseItem =  findArrObjItem('label', value, state.modifyItems[1].options)
                const result = await machineDeliverSetNeedTicket({
                    needTicket: chooseItem.value || value,
                    orderId: state.popForms[0].value,
                })
                if (isValid(result)) {
                    message.success('修改成功')
                }
            } catch(e) {
                console.error(e)
            }
        }
        function closeDialog() {
            loadData()
            state.showDialog = false
        }
        return {
            ...toRefs(state),
            detail,
            loadData,
            getDeliver,
            changeTicketDeliverStatus,
            closeDialog,
        }
    },
}
</script>
<style lang='scss' scoped>
</style>